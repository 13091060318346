/*!
 * Glance Digital Custom JavaScript Programing
 * lance@glance.ca
 *
 */

jQuery(document).ready(function($) {


    // SETTINGS
    // ------------------

    // Velocty Easing
    // ------

    $.Velocity.defaults.easing = 'easeInOutQuart';



    // PLUGINS
    // ------------------


    // 1. Top Header Nav
    if ($('.site-header').length) {
        $('.site-header').switchNav();
    }
    
    // 2. Anchor Link Scroll
    $("a[href^='#']").scrollto({offset_lg: 80, offset_sm: 0});


    // 3. Ajax load more
    if ($('.blog #postslist, .archive #postslist').length) {
        $('.blog #postslist, .archive #postslist').ajaxLoad({
            url         : '/news/',
            post_type   : 'post',
            template    : 'parts/loop-archive.php',
        });
    }


    // Banner Image Parallax (parallax.js)
    if ($('.parallax').length) {
        $('.parallax').parallax({offsetIntertia: 0.4});
    }


    // Show in View (showOnView.js)
    if (!$('body.archive').length) {
        if ($('.showOnView').length) {
            $('.showOnView').showOnView();
        }
    }




    // Init Breakpoint Listeners
    // ------------------
    
    $(this).breakpoints();
});




// ------------------------------------------------------------------------------------------------------------------------------------------------
// PLUGINS


(function($) {



    // 1. Top Navigation : toggle mobile and desktop
    // ------

    $.fn.switchNav = function(options) {
        var settings = $.extend( {
            breakpoint : 'atleast_medium'
        }, options);

        var $top = this;
        var nav_desk = $top.desktopMenu();
        var nav_mobile = null;

        function switchNav(obj, media) {

            // Set Desktop Nav 
            if (media[settings.breakpoint] || media.fallback) {

                if (nav_mobile != null) {
                    nav_mobile.kill();
                    nav_mobile = null;
                }
                // if (nav_desk == null) {
                //     nav_desk = $top.desktopMenu();
                // }

            // Set Mobile Nav 
            } else {

                // if (nav_desk != null) {
                //     nav_desk.kill();
                //     nav_desk = null;
                // }
                if (nav_mobile == null) {
                    nav_mobile = $top.mobileMenu();
                }
            }
        }
        $.subscribe("breakpoints", switchNav);
    };


    // 1.a Mobile Menu

    $.fn.mobileMenu = function() {
        var $container = this;
        var $nav = $container.find('#primary');
        var $primaryDropdown = this.find('.hamburger');

        $nav.velocity('slideUp',{duration:0, display:'none'});

        $primaryDropdown.on('click', function(e) {
            e.preventDefault();
            if ($container.hasClass('open')) {
                $primaryDropdown.removeClass('is-active');
                $container.removeClass('open');
                $nav.velocity('slideUp',{duration:600, display:'none'});

            } else {
                $primaryDropdown.addClass('is-active');
                $container.addClass('open');
                $nav.velocity('slideDown',{duration:600, display:'block'});
            }
        });

        return {
            kill: function() { 
                $primaryDropdown.off('click').removeClass('is-active');
                $nav.velocity('slideDown',{duration:0, display:'block', complete: function() {
                    $nav.attr('style', '');
                }});
            }
        };
    };


    // 1.b Desktop Menu

    $.fn.desktopMenu = function(options) {
        var settings = $.extend( {
            trigger_offset : 100
        }, options);

        var $container = this;
        var $window = $(window);
        var scrollTop = $window.scrollTop();
        var min = false;

        function scrollState() {
            scrollTop = $window.scrollTop();

            // Minimized Top State
            if (scrollTop >= settings.trigger_offset && !min) {
                min = true;
                $container.addClass('minimize');

            // Full Top State
            } else if (scrollTop < settings.trigger_offset && min) {
                min = false;
                $container.removeClass('minimize');
            }
        }

        $window.on('scroll.menustate', scrollState);
        scrollState();


        // Kill desktop functionality
        // ------

        return { 
            kill: function() { 
                $container.removeClass('minimize');
                $window.off('scroll.menustate');
            } 
        };
    };




    // 2. Anchor scroll
    // ------

    $.fn.scrollto = function(options) {
        var settings = $.extend( {
            offset_lg : 0,
            offset_sm : 0,
            exclude : 'tabbed-link',
        }, options);
        
        var offset = settings.offset_lg;

        $.subscribe("breakpoints", function(obj, media) {
            offset = media.small ? settings.offset_sm : settings.offset_lg;
        });

        this.on('click', function(event) {
            var id = $(this).attr('href').split('#')[1];

            if ($('#'+id).length && !$(this).hasClass(settings.exclude)) {
                event.preventDefault();

                var $el = $('#'+id);
                var top = id == 'top' ? $el.offset().top : $el.offset().top - offset;
                $('html').velocity('scroll', {duration: 900, offset: top});
            }
        });

        return this;
    };





    // 3. Ajax load more posts
    // ------

    $.fn.ajaxLoad = function(options) {
        var settings = $.extend( {
            url                : '/news/',
            post_type          : 'post',
            page_action        : 'posts_load',
            paginate           : '#paginateNav',
            post               : '.ajax-post',
            category           : 'cat',
            posts_per_page     : 'posts_per_page',
            template           : 'parts/loop-archive.php',
            language           : false,
        }, options);

        var $container = this;
        var $paginateNav = $container.next(settings.paginate);
        var templateUrl = glance.templateUrl;        
        var pageUrl = settings.url;
        var pageIsLoading = false;
        var category = this.data(settings.category);
        var postsPerPage = this.data(settings.posts_per_page);
        var lang = settings.language ? this.data('lang') : false;
        
        $paginateNav.on('inview', function (event, visible) {
            if (visible) {
                ajaxLoad();
            }
        });
        $paginateNav.on('click.ajaxload', function(e) {
            e.preventDefault();
            ajaxLoad();
        });


        function ajaxLoad() {

            if (!pageIsLoading) {
                pageIsLoading = true;

                $container.addClass('loading');
                $paginateNav.addClass('loading');

                var offset = $container.find(settings.post).length;

                var data = {
                    action : settings.page_action,
                    type : settings.post_type,
                    template : settings.template,
                    category : category,
                    postsPerPage : postsPerPage,
                    offset : offset,
                };

                if (lang) {
                    data.lang = lang;
                }

                $.ajax({
                    url: glance.ajaxUrl,
                    type: 'post',
                    data: data,
                    success: function( result ) {

                        var $result = $(result);
                        var numResults = $result.length;
                        var maxPages = $result.first().data('max');
                        var hidePaginate = numResults < postsPerPage || !( (maxPages * postsPerPage) > (offset + numResults) );

                        if ($result.find('.showOnView').length) {
                            $result.find('.showOnView').css('opacity',0).showOnView();
                        }

                        $result.appendTo($container);

                        if(hidePaginate) $paginateNav.hide();
                        pageIsLoading = false;
                        $container.removeClass('loading');
                        $paginateNav.removeClass('loading');
                    }
                });
            }
        }
        
        return this;
    };





    // Utilities
    // ------

    var aboveView = function(element) {
        return $(window).scrollTop() >= element.offset().top + element.outerHeight();
    };

    var belowFocus = function(element, focusMultipliers) {
        return $(window).height() + $(window).scrollTop() < element.offset().top + $(window).height() * focusMultipliers;
    };
    
    var isInFocus = function(element, focusMultipliers) {
        return (aboveView(element)!=true && belowFocus(element, focusMultipliers)!=true);
    };

})(jQuery);