/**
 * --------------------------------------------------------------------
 * jQuery-Plugin Animate On View
 * Version: 1.0
 * 
 * Animate elements into view when scrolled to
 *
 * by Lance Oliver Cummings, lance@glance.ca
 *
 * Requires inview.js
 *
 * --------------------------------------------------------------------
 * Changelog:
 * v 1.0, 13.10.2018
 *    
 * --------------------------------------------------------------------
 */


(function($) {

    $.fn.showOnView = function(options) {
        var settings = $.extend( {
            count : 'once',
            transitionDelay : 0,
            transitionIn : 'transition.slideUpIn',
            transitionOut : 'transition.slideDownOut',
            transitionSpeed : 600,
            transitionEasing : "easeOutSine",
            display : null,
            staggerGroup : false
        }, options);

        var $window = $(window);
        var onLoad = true;

        if (settings.staggerGroup && $(settings.staggerGroup).length) {
            var ar = settings.staggerGroup.split(' ');
            var $groups = $(ar[0]);
            $groups.each(function() {
                var increment = 100;
                var $els = $(this).find(ar[1]);
                $els.each(function() {
                    $(this).data('onscrolldelay', increment);
                    increment += increment;
                });
            });
        }

        $window.on('scroll.onLoad', function() {
            onLoad = false;
            $window.off('scroll.onLoad');
        });

        this.each(function() {
            var $el = $(this);
            var delay = $el.data('onscrolldelay') !== undefined ? $el.data('onscrolldelay') : settings.transitionDelay;
            var onLoadDelay = $el.data('onloaddelay');
            var thisDelay;
            var thisDisplay = $el.data('display') !== undefined ? $el.data('display') : settings.display;
            if (settings.count == 'once') {

                $el.one('inview', function(event, isInView) {
                    if (isInView) {
                        thisDelay = onLoad && onLoadDelay != undefined ? onLoadDelay : delay;
                        $el.velocity(settings.transitionIn, {delay: thisDelay, duration: settings.transitionSpeed, easing: settings.transitionEasing, display: thisDisplay});
                    }
                });

            } else if (settings.count == 'everytime') {

                $el.on('inview', function(event, isInView) {
                    if (isInView) {
                        thisDelay = onLoad && onLoadDelay != undefined ? onLoadDelay : delay;
                        $el.velocity(settings.transitionIn, {delay: thisDelay, duration: settings.transitionSpeed, easing: settings.transitionEasing});
                    } else {
                        $el.velocity(settings.transitionOut, {duration: settings.transitionSpeed, easing: settings.transitionEasing});
                    }
                });

            }
            
        });
    };
    
})(jQuery);
